body {
  margin: 0;
  font-family: 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins',' sans-serif';
}

.MuiTextField-root {
  width: 100% !important;
}
/* body {
 padding-right: 0px !important;
 overflow: scroll !important;
} */
/* ::-webkit-scrollbar {
   width: 0px
} */
 /* width */
::-webkit-scrollbar {
width: 5px !important;
    height: 5px;
    background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
      border-radius: 10px;
    background-color: #E6E6E6 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}