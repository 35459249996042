/* Login */

.login-root .MuiGrid-root {
    /* padding: 0px !important; */
    margin: 0px;
    overflow: hide;
    ;
}

.login-root .login-sideImage img {
    height: 100vh !important;
    width: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.login-form img {
    height: 4.43rem;
    width: 11.313rem;
    margin-bottom: 5rem;
}

.login-form .MuiTypography-root h2 {
    font-family: Poppins;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.login-form .MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
}

.login-form .MuiStack-root {
    display: flex;
    align-items: flex-start !important;
    padding: 0px 5rem 2.5rem;
}

.login-fields {
    padding: 0px 5rem;
}

.form-fields {
    padding: 12px 16px;
}

.login-fields .form-field {
    display: flex;
    width: 90%;
    padding: 1.56rem 2rem;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--Foundation-Black-black-50, #E6E6E6);
    background: var(--Neutral, #FFF);
}

.login-fields .MuiTypography-root {
    color: var(--Foundation-Black-black-300, #545454);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.login-fields .text-end {
    display: flex;
    justify-content: flex-end;
    color: var(--Primary-Color, #007A6A);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.layout-render {
    padding-left: 220px;
}

.layout-render .outlet {
    padding-top: 100px;
    background-color: #F5F5F5;
}

header {
    background-image: url('../Dis/images/header-bg-img.png') !important;
    height: 84px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* Sidebar */
.sidebar-panel {
    width: 220px;
    z-index: 1005;
    background: #fff;
    bottom: 0;
    margin-top: 0;
    overflow: auto;
    position: fixed;
    left: 0px;
    top: 0px;
    padding: 15px 0px;
}



.logo-sidebar {
    border-bottom: 1px solid #E6E6E6;
    height: 68px;
}

.logo-sidebar a {
    padding: 12px 0px 50px 0px;
    border-bottom: #E6E6E6;
}

.logo-sidebar img {
    height: 46px
}

.nav-item a {
    display: flex;
    align-items: center;
    padding-left: 15px;
    text-decoration: none;
}

.nav-item a a {
    padding: 10px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */


    color: #545454;

}

.nav-item svg {
    color: #545454;
    margin-right: 10px;
}

.align-list {
    display: flex;
    height: 76vh;
    flex-direction: column;
    justify-content: space-between;
}

.side-align ul {
    padding: 0px 12px;
}

.side-align ul li a {
    padding: 10px 12px !important;
    color: #B0B0B0;
}

.side-align ul li a.active {
    background: #F6FAF9;
    color: #007A6A !important;
}

.li-active {
    background: #F6FAF9;
    color: #007A6A !important;
}

.side-align-client .card {
    border: none;
}

/* .nav-item a.active{
    background: #153351;
    color: #FFFFFF;
} */

.align-list li {
    text-decoration: none !important;
    list-style-type: none;

}

/* Header */

header {
    height: 100px;
    width: -webkit-fill-available;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    background: white;

}

.header-left {
    padding-left: 23px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: 'flex';
    align-items: center;
}

.img-li img {
    margin: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.main-search {
    width: 417px;
    height: 60px;
    background: #E9EDF1;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.main-search svg {
    color: #545454;
    ;
}

.search-input {
    background: transparent;
    border: none;
    padding-left: 5px;
}

.dropdown-menu .drop-menu {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dropdown-menu .drop-menu span {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #B4B4B4;
    margin-left: 20px;
}

.calender-root .MuiPopover-paper {
    width: 229px !important;
   
}

.calender-root {
    height: 100% !important;
}

.popover-body {
    padding: 0px;
}

.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.fc .fc-view-harness-active>.fc-view::-webkit-scrollbar {
    width: 5px !important;
    height: 5px;
    background-color: transparent;
}

.fc .fc-view-harness-active>.fc-view::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E6E6E6 !important;
}

.MuiCollapse-root::-webkit-scrollbar {
    width: 2px !important;
    height: 5px;
    background-color: transparent;
}

.MuiCollapse-root::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E6E6E6 !important;
}

.customeScroll::-webkit-scrollbar {
    width: 5px !important;
    height: 1px;
    background-color: transparent;
}

.customeScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E6E6E6 !important;
}

canvas {
    height: 280px;

}
.sidebar-panel::-webkit-scrollbar {
   height: 0px
}
.dropdown-icon {
    height: 16px;
    margin-left: 10px;
}
.calendar-icon {
    height: 16px;
}
.vertical-divider {
     display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #E6E6E6;
  margin: 0 7px;
  vertical-align: middle;
}
@media only screen and (max-width: 1250px) {
    .logo-sidebar img {
        height: 32px
    }

    .logo-sidebar {
        height: 50px;
    }

    header {
        height: 60px !important;
    }

    .sidebar-panel {
        padding: 10px 0px;
    }
    .login-fields {
        padding: 0px 2rem;
    }

    .login-fields .text-end {
        font-size: 16px;
    }

    .login-fields .MuiTypography-root {
        font-size: 16px;
    }

    .login-form img {
        margin-bottom: 3rem;
        height: 4rem;
        width: 10rem;
    }

    .login-form .MuiStack-root {
        padding: 0px 2rem 2.5rem;
    }
    .layout-render .outlet {
        padding-top: 58px;
    }

    canvas {
        height: 270px;
    }
}

@media (min-width: 1259px) {
    .logo-sidebar img {
        height: 32px
    }

    .logo-sidebar {
        height: 50px;
    }

    header {
        height: 60px !important;
    }
    .sidebar-panel {
        padding: 10px 0px;
    }

    .login-form .MuiStack-root {
        padding: 0px 5rem 1.5rem;
    }

    .login-form img {
        margin-bottom: 3rem;
        height: 4rem;
        width: 10rem;
    }

    .login-fields .MuiTypography-root {
        font-size: 16px;
    }

    .login-fields .text-end {
        font-size: 16px;
    }

    .layout-render .outlet {
        padding-top: 58px;
    }

    canvas {
        height: 270px;
    }
}